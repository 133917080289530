@import "../../Styles/variables.scss";

@keyframes slide {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.skills-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-content: space-evenly;
    height: 220px;
    background-color: $background-color;
    &__container {
        display: flex;
        flex-direction: column;
        width: 75%;
        max-width: 1200px;
        height: 100%;
        justify-content: space-evenly;
        align-items: center;
        overflow: hidden;
        position: relative;
        background-color: $background-color;
        @media screen and (min-width: 1600px) {
            max-width: 1400px;
        }
        @media screen and (max-width: 1200px) {
            width: 90%;
        }
        & h2 {
            color: $primary-font-color;
            font-size: 2.5rem;
            overflow: hidden;
            @media screen and (min-width: 1600px){
                font-size: 3rem;
            }
            @media screen and (max-width: 1200px) {
                font-size: 2.2rem;
            }
        }
    }
    &__icons {
        width: auto;
        display: flex;
        gap: 20px;
        align-items: center;
        height: 100px;
        animation: slide 1000s linear infinite;
        overflow: hidden;
        &--icon {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            width: 83px;
            height: 83px;
        }
        & img {
            width: 50px;
            height: 50px;
        }
        & p {
            color: $primary-font-color;
            font-size: 1.6rem;
        }
    }
}
