@import "../../../Styles/variables.scss";

.portfolio {
    width: 100vw;
    height: 100vh;
    background-color: $background-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 30px;
    @media screen and (min-height: 950px) and (max-width: 1048px) {
        height: 70vh;
    }
    @media screen and (max-width: 768px) {
        height: 80vh;
    }
    @media screen and (min-height: 900px) and (max-width: 768px) {
        height: 75vh;
    }

    @media screen and (max-height: 700px) and (max-width: 768px) {
        height: 100vh;
    }
    &__container {
        width: 75%;
        height: 100%;
        max-width: 1200px;
        background: url(../../../Assets/Images/portfolio-bg.webp);
        background-size: cover;
        background-position: center;
        position: relative;
        border-radius: 20px;
        @media screen and (min-width: 1600px) {
            max-width: 1400px;
        }
        @media screen and (max-width: 1200px) {
            width: 90%;
        }
        @media screen and (max-width: 768px) {
            width: 100%;
            border-radius: 0px;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url("../../../Assets/Images/fond-bleu-degrade-luxe-abstrait-bleu-fonce-lisse-banniere-studio-vignette-noire.webp");
            background-size: cover;
            background-position: center;
            opacity: 0.9;
        }
        .portfolio__content {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            @media screen and (max-width: 768px) {
                border-radius: 0px;
            }
            h2 {
                font-size: 2.5rem;
                color: #fff;
                height: 40px;
                @media screen and (min-width: 1600px){
                    font-size: 3rem;
                }
        
                @media screen and (max-width: 768px) {
                    font-size: 2.2rem;
                    margin-top: 25px;
                }
            }
            &--filters {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;
                width: 80%;
                height: 50px;
                margin-top: 20px;
                box-sizing: border-box;
                @media screen and (max-width: 1199px) {
                    margin-top: 0;
                    width: 63%;
                    height: 80px;
                    flex-wrap: wrap;
                    gap: 0;
                    justify-content: space-evenly;
                }
                & button {
                    background: rgba(206, 205, 205, 0.19);
                    backdrop-filter: blur(3px);
                    color: #fff;
                    width: 150px;
                    font-size: 1.6rem;
                    padding: 7px 14px;
                    cursor: pointer;
                    border: rgba(206, 205, 205, 0.19);
                    border-radius: 20px;
                    white-space: no-wrap;
                    flex-shrink: 0;
                    @media screen and (max-width: 768px) {
                        font-size: 1.1rem;
                        width: 100px;
                        padding: 6px 12px;
                    }
                    &:hover {
                        background: rgba(206, 205, 205, 0.29);
                        box-shadow: 1px 1px 5px rgba(255, 255, 255, 0.249);
                        outline: 1px solid white;
                    }
                    &.active {
                        background: rgba(206, 205, 205, 0.35);
                        box-shadow: 2px 2px 5px rgba(255, 255, 255, 0.249);
                        outline: 1px solid white;
                    }
                }
            }
            &--cards {
                width: 100%;
                height: 70%;
                position: relative;
            }
        }
    }
}
