@import "../../Styles/variables.scss";

@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.error-page {
    width: 100vw;
    height: 100vh;
    background: rgb(103, 137, 186);
    background: linear-gradient(
        159deg,
        $primary-color 50%,
        rgba(166, 104, 193, 0.9948354341736695) 100%
    );
    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 100vh;
        opacity: 0;
        animation: appear 2s ease-in-out forwards;
        & p {
            font-size: 1.8rem;
            @media screen and (max-width: 768px) {
                font-size: 1.4rem;
            }
        }
        & img {
            width: 50%;
            height: auto;
            border-radius: 20px;
            @media screen and (max-width: 768px) {
                height: 50%;
                width: auto;
            }
        }
        & a {
            font-size: 2rem;
            font-weight: 600rem;
            text-decoration: underline;
            @media screen and (max-width: 768px) {
                font-size: 1.6rem;
            }
            &:hover {
                opacity: 0.8;
            }
        }
    }
}
