@import "../../../Styles/variables.scss";

@keyframes appear {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

@keyframes slideFromTop {
    from {
        transform: translateY(-300%);
    }
    to {
        transform: translateY(0%);
    }
}

@keyframes slideFromBottom {
    from {
        transform: translateY(0%);
    }
    to {
        transform: translateY(-300%);
    }
}

@mixin HamburgerMenu {
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: -120px;
        margin: auto;
        width: 60%;
        border: 1px solid white;
        box-shadow: -1px 1px 15px rgba(0, 0, 0, 0.6);
        background: rgba(103, 137, 186, 0.6);
        backdrop-filter: blur(4px);
        height: auto;
        z-index: 1000;
        border-radius: 20px;
        padding: 200px 30px 30px 30px;
        li {
            width: 100%;
            padding: 10px 0;
            border-bottom: 1px solid white;
            &:last-child {
                border-bottom: none;
            }
        }
    }
}

header {
    width: 100vw;
    height: 100vh;
    background: rgb(103, 137, 186);
    background: linear-gradient(
        159deg,
        $primary-color 50%,
        rgba(166, 104, 193, 0.9948354341736695) 100%
    );
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    @media screen and (min-height: 819px) and (max-width: 1048px) {
        height: 60vh;
    }
    @media screen and (max-width: 768px) {
        height: auto;
        justify-content: space-between;
    }
    @media screen and (max-height: 450px) and (max-width: 935px) {
        height: 150vh;
    }
    @media screen and (min-height: 850px) and (max-width: 768px) {
        height: 80vh;
    }

    .nav-fixed {
        transform-origin: bottom left;
        animation: appear 700ms ease forwards;
        padding: 0 175px;
        max-width: none;
        position: fixed;
        top: 0;
        width: 100vw;
        height: 70px;
        z-index: 1000; /* pour s'assurer que la barre de navigation est au-dessus des autres éléments */
        background: rgb(103, 137, 186);
        background: linear-gradient(
            159deg,
            $primary-color 50%,
            rgba(166, 104, 193, 0.9948354341736695) 100%
        );
        @media screen and (max-width: 1199px) {
            padding: 0 50px;
        }
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
    nav {
        width: 75%;
        max-width: 1200px;
        display: flex;
        height: 100px;
        align-items: center;
        justify-content: space-between;
        @media screen and (min-width: 1600px) {
            width: 100%;
            max-width: 1400px;
        }
        @media screen and (max-width: 1199px) {
            width: 90%;
        }
        @media screen and (max-width: 768px) {
            flex-direction: column;
            justify-content: center;
        }
        .logo {
            flex-shrink: 0;
            width: 171px;
            height: 50px;
            @media screen and (min-width: 1600px) {
                width: 200px;
                height: 60px;
            }
            @media screen and (max-width: 1199px) {
                width: 150px;
                height: 45px;
            }
            @media screen and (max-width: 768px) {
                width: 200px;
                height: 60px;
            }
        }
        ul {
            width: 520px;
            height: 100%;
            display: flex;
            align-items: center;
            color: white;
            justify-content: space-between;
            @media screen and (max-width: 768px) {
                display: none;
            }
            &.expanded {
                @include HamburgerMenu;
                animation: slideFromTop 300ms ease forwards;
            }
            &.closed {
                @include HamburgerMenu;
                animation: slideFromBottom 0ms forwards;
            }
            &.expanded + .bars {
                display: none;
            }
            &.expanded + .xmark {
                display: block;
            }
            &.hidden {
                display: flex;
                @media screen and (max-width: 768px) {
                    display: none;
                    transform: translateY(-300px);
                    opacity: 0;
                }
            }
            li {
                font-size: 1.8rem;
                width: auto;
                white-space: no-wrap;
                @media screen and (min-width: 1600px) {
                    font-size: 2.2rem;
                    
                }
                @media screen and (max-width: 1199px) {
                    font-size: 1.6rem;
                }
                @media screen and (max-width: 768px) {
                    text-align: center;
                }
            }
            li:hover {
                color: $secondary-color;
            }
            img {
                width: 20px;
                height: 20px;
                display: flex;
                margin-left: auto;
                @media screen and (max-width: 768px) {
                    margin-right: auto;
                }

            }
        }
    }
    & .bars {
        position: absolute;
        display: none;
        @media screen and (max-width: 768px) {
            display: block;
            width: 30px;
            height: 30px;
            top: 20px;
            right: 20px;
            z-index: 1000;
            cursor: pointer;
        }
    }
    & .xmark {
        position: absolute;
        display: none;
        @media screen and (max-width: 768px) {
            display: block;
            width: 30px;
            height: 30px;
            top: 20px;
            right: 20px;
            z-index: 1000;
            cursor: pointer;
        }
    }
}
