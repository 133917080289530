@import "../../../Styles/variables.scss";

.a-propos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-content: space-evenly;
    height: 100vh;
    background-color: $background-color;
    @media screen and (min-height: 819px) and (max-width: 1048px) {
        height: 60vh;
    }
    @media screen and (max-width: 768px) {
        height: 170vh;
    }
    @media screen and (max-height: 450px) and (max-width: 915px) {
        height: 170vh;
    }
    @media screen and (min-height: 840px) and (max-width: 768px) {
        height: 130vh;
    }

    & h2 {
        margin-top: 20px;
        color: $primary-font-color;
        font-size: 2.5rem;
        overflow: hidden;
        height: 35px;
        @media screen and (min-width: 1600px){
            font-size: 3rem;
        }
    
        @media screen and (max-width: 1200px) {
            font-size: 2.2rem;
        }
        @media screen and (max-width: 768px) {
            margin-bottom: 15px;
        }
    }
    &__content {
        display: flex;
        width: 75%;
        max-width: 1200px;
        height: auto;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        @media screen and (min-width: 1600px){
            max-width: 1400px;
        }
        @media screen and (max-width: 1200px) {
            width: 90%;
        }
        @media screen and (max-width: 768px) {
            flex-direction: column;
            width: 95%;
            height: 160vh;
        }
        &--pictures {
            width: 50%;
            position: relative;
            height: 100%;
            overflow: hidden;
            height: 500px;
            transition: transform 0.5s ease;
            @media screen and (min-width: 1600px) {
                width: 38%;
            }
            @media screen and (max-width: 768px) {
                width: 75%;
                height: 45%;
            }
            @media screen and (max-width: 568px) {
                height: 40%;
            }
            @media screen and (max-width: 468px) {
                width: 85%;
            }
            @media screen and (max-width: 398px) {
                height: 35%;
            }
            &-selfie {
                width: 257px;
                height: 350px;
                border-radius: 10px;
                position: absolute;
                top: 18px;
                left: 18px;
                @media screen and (max-width: 1199px) {
                    width: 50%;
                    height: auto;
                }
                @media screen and (max-width: 975px) {
                    width: 55%;
                    height: auto;
                }
                @media screen and (max-width: 768px) {
                    width: 50%;
                    height: auto;
                }
            }
            &-bateau {
                width: 263;
                height: 350px;
                border-radius: 10px;
                position: absolute;
                bottom: 18px;
                right: 18px;
                @media screen and (max-width: 1199px) {
                    width: 50%;
                    height: auto;
                }
                @media screen and (max-width: 975px) {
                    width: 55%;
                    height: auto;
                }
                @media screen and (max-width: 768px) {
                    width: 50%;
                    height: auto;
                }
            }
        }
        &--text {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: space-evenly;
            height: 100%;
            padding: 10px 20px;
            text-align: justify;
            @media screen and (max-width: 1200px) {
                padding: 10px 10px;
            }
            @media screen and (max-width: 768px) {
                width: 70%;
                height: 55%;
            }
            @media screen and (max-width: 568px) {
                height: 60%;
            }
            @media screen and (max-width: 468px) {
                width: 80%;
            }
            @media screen and (max-width: 398px) {
                height: 65%;
            }
            & h3 {
                color: $primary-font-color;
                font-size: 1.8rem;
                padding: 10px 0px;
                @media screen and (min-width: 1600px){
                    font-size: 2.2rem;
                }
                @media screen and (max-width: 1200px) {
                    font-size: 1.6rem;
                    padding: 8px 0px;
                }
                @media screen and (max-width: 768px) {
                    text-align: center;
                }
            }
            p {
                color: $primary-font-color;
                font-size: 1.6rem;
                font-weight: 500;
                padding: 6px 0px;
                @media screen and (min-width: 1600px){
                    font-size: 1.8rem;
                }
                @media screen and (max-width: 1200px) {
                    font-size: 1.4rem;
                    padding: 4px;
                }
            }
        }
    }
}
