@import "../../../Styles/variables.scss";

@keyframes heartbeat {
	50% {
		transform: scale(1.05);
        box-shadow: -2px 3px 5px rgba(0, 0, 0, 0.5);
	}
}

footer {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $background-color;
    @media screen and (max-width: 1200px) {
        height: 150px;
    }
    .footer__container {
        border-top: 0.5px solid #ccc;
        width: 40%;
        height: 100%;
        max-width: 1200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        @media screen and (max-width: 768px) {
            width: 80%;
        }
        &--links {
            display: flex;
            gap: 20px;
            a {
                color: $primary-color;
                font-size: 3.8rem;
                text-decoration: none;
            }
            a:hover {
                color: rgba(103, 137, 186, 0.8);
            }
            & .visually-hidden {
                position: absolute;
                width: 1px;
                height: 1px;
                margin: -1px;
                padding: 0;
                overflow: hidden;
                clip: rect(0, 0, 0, 0);
                border: 0;
            }
        }
        &--copy p {
            color: $primary-font-color;
            font-size: 1.5rem;
        }
        .cv{
            position: fixed;
            bottom: 40px;
            right: 70px;
            border-radius: 20px;
            background: $primary-color;
            transition: transform 500ms ease;
            transform: translateY(300px);
            box-shadow: -2px 3px 4px rgba(0, 0, 0, 0.3);
            @media screen and (max-width: 1200px) {
                bottom: 60px;
                right: 50px;
            }
            @media screen and (max-width: 768px) {
                right: auto;
                bottom: 130px;

            }
            &.visible{
            transform: translateY(0);
            }
            &.animate-heartbeat{
            animation: heartbeat 700ms infinite ease;
            }
            & button{
                z-index: 100;
                font-family: inherit;
                border: 1px solid transparent;
                padding: 10px 15px;
                width: 200px;
                white-space: nowrap;
                overflow: hidden;
                text-align: center;
                background: transparent;
                border-radius: 20px;
                color: white;
                cursor: pointer;
                line-height: 20px; // Correspond à la hauteur du bouton pour stabiliser la hauteur du texte
                @media screen and (max-width: 768px) {
                    width: 60px;
                }
                & span{
                    display: inline;
                    @media screen and (max-width: 768px) {
                        display: none;;
                    }
                }
            }
        }
    }
}
