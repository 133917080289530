@import "../../../Styles/variables.scss";

.contact {
    width: 100vw;
    height: 100vh;
    background-color: $background-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-content: space-evenly;
    position: relative;
    margin-top: 20px;
    @media screen and (min-height: 819px) and (max-width: 1048px) {
        height: 60vh;
    }
    @media screen and (max-width: 768px) {
        height: auto;
    }
    &__container {
        width: 75%;
        height: 100%;
        max-width: 1200px;
        position: relative;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media screen and (min-width: 1600px) {
            max-width: 1400px;
        }
        @media screen and (max-width: 1200px) {
            width: 90%;
        }
        & h2 {
            font-size: 2.5rem;
            color: $primary-font-color;
            text-align: center;
            overflow: hidden;
            @media screen and (min-width: 1600px){
                font-size: 3rem;
            }
            @media screen and (max-width: 768px) {
                font-size: 2.2rem;
                margin-bottom: 35px;
            }
        }
        .contact__content {
            width: 100%;
            height: 90%;
            display: flex;
            align-items: center;
            @media screen and (max-width: 768px) {
                flex-direction: column-reverse;
                gap: 70px;
            }
            &--form {
                width: 50%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1px;
                @media screen and (max-width: 768px) {
                    width: 100%;
                    margin-bottom: 70px;
                }
                & form {
                    width: 75%;
                    height: 95%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;
                    @media screen and (max-width: 1200px) {
                        width: 85%;
                    }
                    @media screen and (max-width: 768px) {
                        width: 85%;
                    }
                    & .visually-hidden {
                        position: absolute;
                        width: 1px;
                        height: 1px;
                        margin: -1px;
                        padding: 0;
                        overflow: hidden;
                        clip: rect(0, 0, 0, 0);
                        border: 0;
                    }
                    & .inputs {
                        width: 100%;
                        height: 60%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        gap: 7px;
                        &__icon {
                            color: $primary-color;
                            font-size: 3.6rem;
                        }
                    }
                    & .captcha {
                        width: 100%;
                        height: 85px;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        & > div {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100% !important;
                            height: 100% !important;
                            overflow: hidden !important;
                        }
                        & > div::-webkit-scrollbar {
                            width: 0px;
                            height: 0px;
                        }
                    }
                }
                & input,
                textarea {
                    outline: none;
                    border: none;
                    width: 100%;
                    height: 40px;
                    padding: 10px;
                    border-radius: 10px;
                    border-bottom: 1px solid #ccc;
                    border-left: 7px solid $primary-color;
                    font-size: 1.6rem;
                    resize: none;
                    color: $primary-font-color;
                    font-weight: 400;
                    font-family: "montserrat", "sans-serif";
                }
                & input:focus,
                textarea:focus {
                    background-color: #fdfdfd;
                }
                & textarea {
                    height: 140px;
                }
                & .submit-button {
                    width: 150px;
                    height: 50px;
                    background-color: $primary-color;
                    color: white;
                    font-size: 1.6rem;
                    outline: none;
                    border: none;
                    border-radius: 10px;
                    cursor: pointer;
                    &:hover {
                        background-color: rgba(103, 137, 186, 0.8);
                    }
                    &.disabled {
                        background-color: #948b8b;
                        cursor: not-allowed;
                    }
                }
            }
            &--links {
                width: 50%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                @media screen and (max-width: 768px) {
                    width: 80%;
                }
                & .phone-volume {
                    color: $primary-color;
                    font-size: 3.6rem;
                    transform: translateY(-36px);
                    @media screen and (max-width: 768px) {
                        transform: translateY(0px);
                        margin-bottom: 13px;
                    }
                }
                &-image {
                    width: 266px;
                    height: 360px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    overflow: hidden;
                    border-radius: 20px;
                    @media screen and (min-width: 1600px) {
                        width: 300px;
                        height: 400px;}
                    @media screen and (max-width: 768px) {
                        width: 210px;
                        height: 300px;
                    }
                    & img {
                        width: 266px;
                        height: 360px;
                        object-fit: cover;
                        border-radius: 20px;
                        transition: 150ms;
                        @media screen and (min-width: 1600px) {
                            width: 300px;
                            height: 400px;
                        }
                    }
                    &:hover img {
                        scale: (1.1);
                        transform: rotate(1deg);
                    }
                }
                .phone-btn {
                    top: 0;
                    right: 0;
                    display: flex;
                    position: absolute;
                    outline: none;
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                    & a {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        & span {
                            display: flex;
                            justify-content: space-evenly;
                            width: 80%;
                            height: 25px;
                            overflow: hidden;
                            margin-top: 50px;
                            font-family: "montserrat", "sans-serif";
                            font-size: 1.6rem;
                            font-weight: 500;
                            color: $primary-color;
                            opacity: 0;
                            transform: translateY(-100px);
                            transition: 300ms ease;
                            white-space: no-wrap;
                            @media screen and (min-width: 1600px) {
                                font-size: 2rem;
                            }
                            @media screen and (max-width: 1199px) {
                                font-size: 1.4rem;
                                width: 85%;
                            }
                        }
                    }
                    &:hover a span {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
        }
    }
}
