@import "../../Styles/variables.scss";

.card-group > .card {
    box-shadow: none;
}

@keyframes bigCard2 {
    from {
        transform: none;
        box-shadow: none;
    }
    to {
        transform: translate(-75%, 16%) rotate(-24deg) scale(1);
        box-shadow: -1vmin 2vmin 5vmin rgba(255, 255, 255, 0.149);
    }
}

@keyframes bigCard4 {
    from {
        transform: none;
        box-shadow: none;
    }
    to {
        transform: translate(-5%, -38%) rotate(-8deg);
        box-shadow: -1vmin 2vmin 5vmin rgba(255, 255, 255, 0.149);
    }
}

@keyframes bigCard6 {
    from {
        transform: none;
        box-shadow: none;
    }
    to {
        transform: translate(2%, 50%) rotate(8deg);
        box-shadow: -1vmin 2vmin 5vmin rgba(255, 255, 255, 0.149);
    }
}

@keyframes bigCard8 {
    from {
        transform: none;
        box-shadow: none;
    }
    to {
        transform: translate(68%, 26%) rotate(24deg);
        box-shadow: -1vmin 2vmin 5vmin rgba(255, 255, 255, 0.149);
    }
}

@keyframes littleCard1 {
    from {
        transform: none;
    }
    to {
        transform: translate(200%, -160%) rotate(-15deg) scale(1);
    }
}

@keyframes littleCard3 {
    from {
        transform: none;
    }
    to {
        transform: translate(160%, 170%) rotate(15deg) scale(1);
    }
}

@keyframes littleCard5 {
    from {
        transform: none;
    }
    to {
        transform: translate(-200%, -170%) rotate(15deg) scale(1);
    }
}

@keyframes littleCard7 {
    from {
        transform: none;
    }
    to {
        transform: translate(-280%, 140%) rotate(-15deg) scale(1);
    }
}

@media screen and (max-width: 1199px) {
    .card-group.active > .big-card:nth-child(2) {
        animation: bigCard2 300ms 300ms ease forwards;
    }

    .card-group.active > .big-card:nth-child(4) {
        animation: bigCard4 300ms 300ms ease forwards;
    }

    .card-group.active > .big-card:nth-child(6) {
        animation: bigCard6 300ms 300ms ease forwards;
    }

    .card-group.active > .big-card:nth-child(8) {
        animation: bigCard8 300ms 300ms ease forwards;
    }

    .card-group.active > .little-card:nth-child(1) {
        animation: littleCard1 300ms 300ms ease forwards;
    }

    .card-group.active > .little-card:nth-child(3) {
        animation: littleCard3 300ms 300ms ease forwards;
    }

    .card-group.active > .little-card:nth-child(5) {
        animation: littleCard5 300ms 300ms ease forwards;
    }

    .card-group.active > .little-card:nth-child(7) {
        animation: littleCard7 300ms 300ms ease forwards;
    }
}

.card-group[data-status="active"] {
    width: 100%;
}

.card-group[data-status="after"] {
    transform: translateX(50%) scale(0);
}

.card-group[data-status="before"] {
    transform: translateX(-50%) scale(0);
}

.card-group[data-status="becoming-active-from-after"] {
    transform: translateX(50%) scale(0);
}

.card-group[data-status="becoming-active-from-before"] {
    transform: translateX(-50%) scale(0);
}

.card-groups,
.card-group,
.big-card {
    overflow: hidden;
    width: 40vmin;
    @media screen and (max-width: 1180px) {
        width: 38vmin;
    }
    @media screen and (max-width: 768px) {
        width: 35vmin;
    }
    @media screen and (min-height: 800px) and (max-width: 1199px) {
        width: 30vmin;
    }
}
.card-groups {
    .card-group {
        transition: transform 500ms ease;
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
        .card {
            box-shadow: 1px 1px 25px rgba(255, 255, 255, 0.249);
            position: absolute;
            transition: transform 300ms cubic-bezier(0.05, 0.43, 0.25, 0.95);
        }
        .big-card {
            border-radius: 1vmin;
            position: absolute;
            transition: ease 300ms;
            left: 38%;
            top: 20%;
            border: 1px solid rgb(247, 246, 246, 0.6);
            aspect-ratio: 14.5 / 10;
            background-size: cover;
            background-position: center;
            @media screen and (min-width: 1199px) {
                left: 38%;
            }
            @media screen and (max-width: 1199px) {
                left: 40%;
            }
            @media screen and (min-height: 1200px) and (max-width: 1024px){
                left: 36%;
            }
            @media screen and (min-height: 800px) and (max-height:1199px)and (max-width: 1199px) {
                left: 38%;
            }
            @media screen and (max-height: 768px) and (max-width: 1024px) {
                left: 37%;
            }
            @media screen and (min-height: 800px) and (max-width: 768px) {
                left: 38%;
            }
            @media screen and (max-height: 650px) and (max-width: 768px) {
                left: 38%;
            }
            @media screen and (max-height: 800px) and (max-width: 768px) {
                left: 34%;
            }

            &:nth-child(2) {
                background-image: url("../../Assets/Portfolio/Kasa/Kasa-gif.gif");
                transform: translateX(-10%) rotate(-1deg) scale(0.7);
                background-repeat: no-repeat;
                background-color: white;
                background-origin: content-box;
                padding: 8px;
            }

            &:nth-child(4) {
                background-image: url("../../Assets/Portfolio/Kasa/Kasa-desktop.webp");
                transform: rotate(2deg);
            }

            &:nth-child(6) {
                background-color: rgb(250, 247, 247, 0.86);
                background-image: url("../../Assets/Portfolio/Kasa/Kasa-logement.PNG");
                transform: translateX(-6%) rotate(-3deg);
                background-origin: content-box;
                padding-top: 10px;
            }

            &:nth-child(8) {
                background-image: url("../../Assets/Portfolio/Kasa/kasa-banner.png");
                transform: translate(10%, 3%) rotate(5deg);
            }

            &.group-1 {
                &:nth-child(2) {
                    background-image: url("../../Assets/Portfolio/Bluel/Bluel-gif.gif");
                    padding: 2px;
                    background-repeat: no-repeat;
                    background-color: white;
                    background-origin: content-box;
                    padding: 8px;
                }

                &:nth-child(4) {
                    background-image: url("../../Assets/Portfolio/Bluel/Bluel-add.PNG");
                    background-repeat: no-repeat;
                    background-color: white;
                    background-origin: content-box;
                    padding: 8px;
                }
                &:nth-child(6) {
                    background-color: white;
                    background-image: url("../../Assets/Portfolio/Bluel/Bluel-mobile.webp");
                    background-origin: content-box;
                    padding: 35px;
                    background-repeat: no-repeat;                    
                }
                &:nth-child(8) {
                    background-image: url("../../Assets/Portfolio/Bluel/Bluel-logo.PNG");
                    background-repeat: no-repeat;
                    background-origin: content-box;
                    padding-left: 15px;
                }
            }
            &.group-2 {
                &:nth-child(2) {
                    background-image: url("../../Assets/Portfolio/Grimoire/Grimoire-merci.webp");
                    padding: 2px;
                    background-origin: content-box;
                    padding-top: 35px;
                    background-repeat: no-repeat;
                    background-color: white;
                }
                &:nth-child(4) {
                    background-image: url("../../Assets/Portfolio/Grimoire/Grimoire-review.PNG");
                }
                &:nth-child(6) {
                    background-color: white;
                    background-image: url("../../Assets/Portfolio/Grimoire/Grimoire-rated.webp");
                    background-origin: content-box;
                    padding-top: 20px;
                    background-repeat: no-repeat;
                }
                &:nth-child(8) {
                    background-image: url("../../Assets/Portfolio/Grimoire/Grimoire-logo.png");
                    background-position: left;
                }
            }
            &.group-3 {
                &:nth-child(2) {
                    background-image: url("../../Assets/Portfolio/Booki/Booki-gif.gif");
                    background-repeat: no-repeat;
                    background-color: white;
                    background-origin: content-box;
                    padding: 6px;
                }

                &:nth-child(4) {
                    background-image: url("../../Assets/Portfolio/Booki/Booki-search.PNG");
                    background-origin: content-box;
                    background-repeat: no-repeat;
                    padding: 10px;
                    background-color: white;
                }
                &:nth-child(6) {
                    background-color: rgb(250, 247, 247, 0.86);
                    background-image: url("../../Assets/Portfolio/Booki/Booki-hebergements.webp");
                    background-origin: content-box;
                    padding: 5px;
                }
                &:nth-child(8) {
                    background-image: url("../../Assets/Portfolio/Booki/Booki-logo.PNG");
                    background-position: right;
                }
            }
            &.group-4 {
                &:nth-child(2) {
                    background-image: url("../../Assets/Portfolio/Nina/Nina-tweet.webp");
                    padding: 8px;
                    background-origin: content-box;
                    background-repeat: no-repeat;
                    background-color: white;
                }

                &:nth-child(4) {
                    background-image: url("../../Assets/Portfolio/Nina/Nina-desktop.webp");
                    background-repeat: no-repeat;
                    background-color: white;
                    padding: 10px;
                    background-origin: content-box;
                }
                &:nth-child(6) {
                    background-color: white;
                    background-image: url("../../Assets/Portfolio/Nina/Score.PNG");
                    background-origin: content-box;
                    padding: 23px;
                    background-repeat: no-repeat;
                }
                &:nth-child(8) {
                    background-image: url("../../Assets/Portfolio/Nina/banner-nina-carducci.webp");
                    background-position: left;
                }
            }
        }
        .little-card {
            transform: scale(0);
            width: 12vmin;
            border-radius: 2vmin;
            left: 50%;
            top: 42%;
            transform: translate(-50%, -50%);
            pointer-events: none;
            aspect-ratio: 7 / 7;
            border: 1px solid white;
            background: rgba(206, 205, 205, 0.445);
            filter: blur(0.6px);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            background-origin: content-box;
            padding: 5px;
            @media screen and (max-width: 1200px) {
                width: 11vmin;
            }
            @media screen and (max-width: 1180px) {
                top: 40%;
            }
            @media screen and (max-width: 768px) {
                top: 30%;
            }
            &.group-0 {
                &:nth-child(1) {
                    background-image: url("../../Assets/Icons/html-icon.svg");
                }
                &:nth-child(3) {
                    background-image: url("../../Assets/Icons/sass-icon.svg");
                }
                &:nth-child(5) {
                    background-image: url("../../Assets/Icons/figma.svg");
                }
                &:nth-child(7) {
                    background-image: url("../../Assets/Icons/react-icon.svg");
                }
            }
            &.group-1 {
                &:nth-child(1) {
                    background-image: url("../../Assets/Icons/html-icon.svg");
                }
                &:nth-child(3) {
                    background-image: url("../../Assets/Icons/css-icon.svg");
                }
                &:nth-child(5) {
                    background-image: url("../../Assets/Icons/mongodb-icon.svg");
                }
                &:nth-child(7) {
                    background-image: url("../../Assets/Icons/javascript-icon.svg");
                }
            }
            &.group-2 {
                &:nth-child(1) {
                    background-image: url("../../Assets/Icons/Moongoose-icon.png");
                }
                &:nth-child(3) {
                    background-image: url("../../Assets/Icons/mongodb-icon.svg");
                }
                &:nth-child(5) {
                    background-image: url("../../Assets/Icons/nodejs-icon.svg");
                }
                &:nth-child(7) {
                    background-image: url("../../Assets/Icons/vercel.png");
                }
            }
            &.group-3 {
                &:nth-child(1) {
                    background-image: url("../../Assets/Icons/html-icon.svg");
                }
                &:nth-child(3) {
                    background-image: url("../../Assets/Icons/css-icon.svg");
                }
                &:nth-child(5) {
                    background-image: url("../../Assets/Icons/figma.svg");
                }
                &:nth-child(7) {
                    background-image: url("../../Assets/Icons/git.png");
                }
            }
            &.group-4 {
                &:nth-child(1) {
                    background-image: url("../../Assets/Icons/html-icon.svg");
                }
                &:nth-child(3) {
                    background-image: url("../../Assets/Icons/css-icon.svg");
                }
                &:nth-child(5) {
                    background-image: url("../../Assets/Icons/javascript-icon.svg");
                }
                &:nth-child(7) {
                    background-image: url("../../Assets/Icons/lighthouse.png");
                }
            }
        }
    }
    & .arrows {
        visibility: visible;
        display: flex;
        align-items: end;
        justify-content: center;
        gap: 20px;
        height: 65px;
        font-size: 4.6rem;
        width: 100%;
        margin-top: 0px;
        position: absolute;
        bottom: 0px;
        overflow: hidden;
        color: rgb(253, 235, 225, 0.5);
        filter: blur(0.5px);
        @media screen and (max-width: 768px) {
            margin-bottom: 23px;
        }
        &__left {
            cursor: pointer;
            &:hover {
                color: white;
            }
            &:active {
                color: rgb(253, 235, 225, 0.8);
            }
        }
        &__right {
            cursor: pointer;
            &:hover {
                color: white;
            }
            &:active {
                color: rgb(253, 235, 225, 0.8);
            }
        }
        &__hidden {
            visibility: hidden;
        }
    }
}

.card-group:hover > .big-card:nth-child(2) {
    transform: translate(-75%, 16%) rotate(-24deg) scale(1);
    box-shadow: -1vmin 2vmin 5vmin rgba(255, 255, 255, 0.149);
}

.card-group:hover > .big-card:nth-child(4) {
    transform: translate(-5%, -38%) rotate(-8deg);
    box-shadow: -1vmin 2vmin 5vmin rgba(255, 255, 255, 0.149);
}

.card-group:hover > .big-card:nth-child(6) {
    transform: translate(2%, 50%) rotate(8deg);
    box-shadow: -1vmin 2vmin 5vmin rgba(255, 255, 255, 0.149);
}

.card-group:hover > .big-card:nth-child(8) {
    transform: translate(68%, 26%) rotate(24deg);
    box-shadow: -1vmin 2vmin 5vmin rgba(255, 255, 255, 0.149);
}

.card-group:hover > .little-card:nth-child(1) {
    transform: translate(200%, -160%) rotate(-15deg) scale(1);
}

.card-group:hover > .little-card:nth-child(3) {
    transform: translate(160%, 170%) rotate(15deg) scale(1);
}

.card-group:hover > .little-card:nth-child(5) {
    transform: translate(-200%, -170%) rotate(15deg) scale(1);
}

.card-group:hover > .little-card:nth-child(7) {
    transform: translate(-280%, 140%) rotate(-15deg) scale(1);
}

.counter {
    visibility: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    height: 25px;
    font-size: 1.6rem;
    width: 100%;
    overflow: hidden;
    position: absolute;
    bottom: 58px;
    @media screen and (max-width: 768px) {
        bottom: 70px;
        font-size: 1.2rem;
    }
}
